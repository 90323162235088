import {
  sentryTrackingFinishChildOp,
  sentryTrackingFinishTransaction,
  sentryTrackingStartChildOp,
  sentryTrackingStartTransaction,
} from './sentry-tracking';
import { getPageTypeFromUrl } from '../utils/url-helper';
import { usePerformanceStore } from '../store';
import { dispatchEditorInitFail, dispatchEditorLongInitTime } from '@/core/analytics';

const EDITOR_INIT_TRANSACTION = 'EDITOR_INIT_TRANSACTION';

export enum TrackInitTimeChildOp {
  AUTHENTICATE = 'AUTHENTICATE',
  GET_SHOP_SHOPIFY = 'GET_SHOP_SHOPIFY',
  UPGRADE_SHOP_VERSION = 'UPGRADE_SHOP_VERSION',
  SHOW_EDITOR_SKELETON_TIME = 'SHOW_EDITOR_SKELETON_TIME',
  EMPTY_IFRAME_DISPLAY_TIME = 'EMPTY_IFRAME_DISPLAY_TIME',
  FETCH_BUILDER_SETTING = 'FETCH_BUILDER_SETTING',
  FETCH_BUILDER_CONFIG_ICON = 'FETCH_BUILDER_CONFIG_ICON',
  FETCH_GLOBAL_STYLE = 'FETCH_GLOBAL_STYLE',
  FETCH_THEME_SECTION = 'FETCH_THEME_SECTION',
  FETCH_THEME_PAGE = 'FETCH_THEME_PAGE',
  BUILDER_IFRAME_LOAD_TIME = 'BUILDER_IFRAME_LOAD_TIME',
}

export enum TrackInitTimeChildOpStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  TIME_OUT = 'TIME_OUT',
  SKIP = 'SKIP',
}

const EDITOR_CHILD_SPAN = Object.values(TrackInitTimeChildOp);

type ChildOperationFinishOption = {
  TrackInitTimeChildOpStatus: TrackInitTimeChildOpStatus;
};

let timeout: NodeJS.Timeout | null = null;

const getRunningOperationList = () => {
  const performanceStore = usePerformanceStore();
  const transactionObject = performanceStore.getTransactionByName(EDITOR_INIT_TRANSACTION);
  if (!transactionObject) return;
  return Array.from(transactionObject.childOperationList?.keys() || []);
};

export const trackInitTimeStart = () => {
  const pageType = getPageTypeFromUrl();
  sentryTrackingStartTransaction(EDITOR_INIT_TRANSACTION, {
    childOperationNameList: EDITOR_CHILD_SPAN,
    operationName: pageType,
  });

  timeout = setTimeout(() => {
    const runningOperationList = getRunningOperationList();
    if (!runningOperationList) return;
    dispatchEditorLongInitTime(runningOperationList);
  }, 60000);
};

export const trackInitTimeStartChildOp = (operationName: TrackInitTimeChildOp) => {
  sentryTrackingStartChildOp(EDITOR_INIT_TRANSACTION, operationName);
  switch (operationName) {
    case TrackInitTimeChildOp.FETCH_THEME_PAGE:
      sentryTrackingFinishChildOp(EDITOR_INIT_TRANSACTION, TrackInitTimeChildOp.FETCH_THEME_SECTION, {
        status: TrackInitTimeChildOpStatus.SKIP,
      });
      break;
    case TrackInitTimeChildOp.FETCH_THEME_SECTION:
      sentryTrackingFinishChildOp(EDITOR_INIT_TRANSACTION, TrackInitTimeChildOp.FETCH_THEME_PAGE, {
        status: TrackInitTimeChildOpStatus.SKIP,
      });
      break;
  }
};

export const trackInitTimeFinishChildOp = (
  operationName: TrackInitTimeChildOp,
  options: ChildOperationFinishOption,
) => {
  sentryTrackingFinishChildOp(EDITOR_INIT_TRANSACTION, operationName, { status: options.TrackInitTimeChildOpStatus });

  if (
    ![TrackInitTimeChildOpStatus.FAIL, TrackInitTimeChildOpStatus.TIME_OUT].includes(options.TrackInitTimeChildOpStatus)
  ) {
    return;
  }

  if (operationName === TrackInitTimeChildOp.UPGRADE_SHOP_VERSION) return;

  sentryTrackingFinishTransaction(EDITOR_INIT_TRANSACTION, { forceFinish: true });
  dispatchEditorInitFail(operationName, options.TrackInitTimeChildOpStatus);
  if (timeout) {
    clearTimeout(timeout);
  }
};
