import type { TrackInitTimeChildOpStatus } from '@/core/performance';

export enum ANALYTIC_EVENT {
  EDITOR_OPEN = 'EDITOR_OPEN',
  EDITOR_LONG_INIT_TIME = 'EDITOR_LONG_INIT_TIME',
  EDITOR_INIT_FAIL = 'EDITOR_INIT_FAIL',
  EDITOR_PUBLISH = 'EDITOR_PUBLISH',
  EDITOR_PUBLISH_FAIL = 'EDITOR_PUBLISH_FAIL',
  EDITOR_SAVE_FAIL = 'EDITOR_SAVE_FAIL',
}

export const dispatchEditorLongInitTime = (runningOperation: string[]) => {
  const reportLongInitTime = new CustomEvent(ANALYTIC_EVENT.EDITOR_LONG_INIT_TIME, {
    bubbles: true,
    detail: {
      runningOperation,
    },
  });
  document.dispatchEvent(reportLongInitTime);
};

export const listenEditorLongInitTime = (callback: (runningOperation: []) => void) => {
  const abortController = new AbortController();
  document.addEventListener(
    ANALYTIC_EVENT.EDITOR_LONG_INIT_TIME,
    (event) => {
      const runningOperation = (event as any)?.detail.runningOperation;
      callback(runningOperation);
    },
    { signal: abortController.signal },
  );

  return abortController;
};

export const dispatchEditorInitFail = (operationName: string, status: TrackInitTimeChildOpStatus) => {
  const reportInitFail = new CustomEvent(ANALYTIC_EVENT.EDITOR_INIT_FAIL, {
    bubbles: true,
    detail: {
      operationName,
      status,
    },
  });
  document.dispatchEvent(reportInitFail);
};

export const listenEditorInitFail = (callback: (operationName: string, status: TrackInitTimeChildOpStatus) => void) => {
  const abortController = new AbortController();
  document.addEventListener(
    ANALYTIC_EVENT.EDITOR_INIT_FAIL,
    (event) => {
      const operationName = (event as any)?.detail.operationName;
      const status = (event as any)?.detail.status;
      callback(operationName, status);
    },
    { signal: abortController.signal },
  );

  return abortController;
};
