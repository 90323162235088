import { client } from '@/api/gatewayClient';
import useNotification from '@/composables/useNotification';
import useShopStore from '@/stores/shop';
import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';
import { useCurrentShopQuery } from '@/api/app/queries/current-shop.generated';
import useILPTotalCount from 'gemx/src/modules/instant/hooks/useILPTotalCount';
import { TrackInitTimeChildOp, trackInitTimeFinishChildOp, TrackInitTimeChildOpStatus } from '@/core/performance';

export const useShopQuery = ({
  shopID,
  shopHandle,
}: {
  shopID?: ComputedRef<string | undefined> | Ref<string>;
  shopHandle?: string;
}) => {
  const shopStore = useShopStore();
  useILPTotalCount();
  const { handleByStatus, handleError } = useNotification();
  if (shopHandle == 'unknown') {
    handleByStatus({
      statusCode: 401,
      code: 401999,
      message: new Error('Shop not found'),
    });
    return;
  }
  const { data } = useCurrentShopQuery(
    {},
    {
      onSuccess: (result) => {
        const shop = result.currentShop;
        if (shop) {
          shopStore.setShop(shop);
          trackInitTimeFinishChildOp(TrackInitTimeChildOp.GET_SHOP_SHOPIFY, {
            TrackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.SUCCESS,
          });
          client.setHeader('X-GemX-Shop-ID', shop.id);
        } else {
          trackInitTimeFinishChildOp(TrackInitTimeChildOp.GET_SHOP_SHOPIFY, {
            TrackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.FAIL,
          });
          handleByStatus({
            statusCode: 401,
            code: 401999,
            message: new Error('Shop not found'),
          });
        }
      },
      onError: (error) => {
        handleError(error);
        trackInitTimeFinishChildOp(TrackInitTimeChildOp.GET_SHOP_SHOPIFY, {
          TrackInitTimeChildOpStatus: TrackInitTimeChildOpStatus.FAIL,
        });
      },
      enabled: !!shopHandle || computed(() => !!shopID?.value && shopID?.value !== '0'),
      cacheTime: 0, // force cacheTime=0 because multi layout(instant shop not null, welcome instant shop is null)
    },
  );

  return computed(() => data.value?.currentShop);
};
