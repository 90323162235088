import type { Span, Transaction } from '@sentry/vue';

import { defineStore } from 'pinia';

type TransactionObjectType = {
  transaction: Transaction;
  childOperationList?: Map<string, Span | undefined>;
};

export type StateType = {
  transactionMap: Map<string, TransactionObjectType>;
};

export const usePerformanceStore = defineStore('performance', {
  state: (): StateType => ({
    transactionMap: new Map(),
  }),
  getters: {
    getTransactionMap(state: StateType) {
      return state.transactionMap;
    },
  },
  actions: {
    addTransaction(transaction: Transaction, childOperationList?: Map<string, Span | undefined>) {
      this.transactionMap.set(transaction.name, { transaction, childOperationList });
    },
    updateTransaction(transactionName: string, transactionObject: TransactionObjectType) {
      this.transactionMap.set(transactionName, transactionObject);
    },
    getTransactionByName(transactionName: string) {
      return this.transactionMap.get(transactionName);
    },
  },
});
