import { useThemePagesQuery } from '@/api/app/themePagesCount.generated';
import useNotification from '@/composables/useNotification';
import useShopStore from '@/stores/shop';
const { handleError } = useNotification();

const useILPTotalCount = () => {
  const shopStore = useShopStore();
  const shopID = computed(() => shopStore.getShopId);

  return useThemePagesQuery(
    {
      where: {
        type: 'STATIC',
      },
    },
    {
      enabled: computed(() => !!shopID?.value && shopID?.value !== '0'),
      refetchOnMount: true,
      staleTime: 5000,
      onError: handleError,
      onSuccess: (result) => {
        shopStore.setTotalIlPCount(result.themePages?.totalCount ?? 0);
      },
    },
  );
};

export default useILPTotalCount;
