<script lang="ts" setup>
import { TransitionRoot, TransitionChild } from '@headlessui/vue';
import { ref } from 'vue';
import type { ModalProps } from '../types/common';
import { GButtonV2 } from '@gem/uikit';
import { useDraggable, onClickOutside } from '@vueuse/core';
import { cn } from '../helpers/common';
import { GTooltip } from '..';
import { useKeyboardShortcut } from '../composables/useKeyboardShortcut';

const emits = defineEmits(['ok', 'cancel', 'close', 'refresh', 'outside']);

const props = withDefaults(defineProps<ModalProps>(), {
  type: 'normal',
  btnCancelType: 'secondary',
  btnOkType: 'primary',
  modelValue: false,
  labelCancel: 'Cancel',
  labelOk: 'Save',
  showBtnClose: false,
  showBtnRefresh: false,
  isDraggable: false,
  showLineTop: true,
  showLineBottom: true,
  placementCloseTooltip: 'left',
  clickOutSideClose: true,
  lightMode: true,
});

const focusRef = ref(null);
const target = ref(null);
const modalContainer = ref<HTMLElement>();
onClickOutside(target, () => emits('outside'));

const { style } = useDraggable(modalContainer, {
  initialValue: { x: 280, y: 50 },
  // preventDefault: true,
});

const onClose = () => {
  props.clickOutSideClose && emits('close');
};
useKeyboardShortcut({
  callback(event) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      emits('close');
    }
  },
});
</script>

<template>
  <Teleport to="body">
    <TransitionRoot appear :show="isOpen" as="template">
      <div :class="cn('inset-0 z-[500] flex items-center justify-center', { fixed: !contentFixed }, dialogClass)">
        <div v-if="!hideOverlay" class="fixed inset-0 bg-black opacity-60" aria-hidden="true" @click="onClose" />
        <div
          ref="target"
          :data-mode="lightMode"
          :class="cn('modal-control  fixed z-50 max-h-[90%] max-w-[90%] ', containerClass)"
          :style="[isDraggable ? style : {}]"
          :data-is-open="isOpen">
          <component
            :is="!hiddenTransition ? TransitionChild : 'div'"
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            class="rounded-xxl h-full bg-white"
            leave-to="opacity-0">
            <div
              data-test="editor-control-setting-g-modal"
              :class="
                cn(
                  '!shadow-6dp rounded-xxl flex h-full w-full min-w-[400px] transform flex-col overflow-hidden text-left align-middle transition-all',
                  {
                    'shadow-modal': hideOverlay,
                    'w-[400px]': type === 'confirm',
                  },
                  modalClass,
                )
              ">
              <div
                v-if="!hideHeader"
                ref="modalContainer"
                data-test="editor-control-modal-title"
                :class="
                  cn(
                    'bg-light-100 text-dark-400 relative z-20 flex items-center',
                    {
                      'cursor-move': isDraggable,
                    },
                    headerClass,
                  )
                ">
                <div
                  class="text-16 text-text-light-500 relative flex !h-[52px] w-full items-center justify-between truncate pl-16 font-semibold leading-6 tracking-[-0.32px]"
                  :class="headerTitleClass">
                  <slot name="title"></slot>
                </div>
                <template v-if="showBtnRefresh">
                  <GButtonV2
                    type="ghost"
                    tabindex="-1"
                    only-icon="refresh-select"
                    icon-loading="small-loading"
                    icon-view-box="0 0 16 16"
                    icon-size="16"
                    size="large"
                    :loading="refreshing"
                    :light-mode="lightMode"
                    @click="$emit('refresh')">
                  </GButtonV2
                ></template>

                <template v-if="showBtnClose">
                  <g-tooltip v-if="closeTooltipContent" :placement="placementCloseTooltip" class="flex py-0 pl-0">
                    <GButtonV2
                      type="ghost"
                      tabindex="-1"
                      only-icon="close"
                      size="small"
                      :light-mode="lightMode"
                      data-test="editor-control-modal-button-close"
                      @click="$emit('close')">
                    </GButtonV2>

                    <template #content>
                      <span class="text-12 flex items-center">{{ closeTooltipContent || 'Cancel' }}</span>
                    </template>
                  </g-tooltip>
                  <div
                    class="group/button flex h-[52px] w-[52px] flex-shrink-0 cursor-pointer items-center justify-center"
                    @click="$emit('close')">
                    <GButtonV2
                      type="ghost"
                      tabindex="-1"
                      only-icon="close"
                      :light-mode="lightMode"
                      size="semi-medium"
                      data-test="editor-control-modal-button-close">
                    </GButtonV2>
                  </div>
                </template>
              </div>
              <div
                data-test="editor-control-modal-body"
                :class="cn('relative z-10 flex-grow overflow-y-auto bg-white ', bodyClass)">
                <slot></slot>
              </div>
              <div
                v-if="!hideActions"
                :class="
                  cn('relative flex !h-[64px] flex-shrink-0 items-center justify-end gap-8 bg-white px-16', footerClass)
                ">
                <slot name="footer"></slot>

                <GButtonV2
                  v-if="!hideBtnCancel"
                  data-test="model-btn-cancel"
                  :type="btnCancelType"
                  :light-mode="lightMode"
                  @click="$emit('cancel')"
                  >{{ labelCancel }}</GButtonV2
                >

                <GButtonV2
                  v-if="!hideBtnOk"
                  ref="focusRef"
                  data-test="model-btn-ok"
                  :type="btnOkType"
                  :disable="btnOkDisable"
                  :loading="loading"
                  :light-mode="lightMode"
                  @click="!btnOkDisable && $emit('ok')">
                  {{ labelOk }}
                </GButtonV2>
              </div>
              <div
                v-if="!hideHeader && showLineTop && isOpen"
                class="show-Line z-15 bg-light-400 absolute top-[52px] left-0 h-[1px] w-[100%]"
                :class="showLineCustom"></div>
              <div
                v-if="!hideActions && showLineBottom && isOpen"
                class="show-Line z-15 bg-light-400 absolute bottom-[64px] left-0 h-[1px] w-full"
                :class="showLineCustom"></div>
            </div>
          </component>
        </div>
      </div>
    </TransitionRoot>
  </Teleport>
</template>
