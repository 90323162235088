import { getConfig } from '@/config';
import * as Sentry from '@sentry/vue';
import { GraphQLClient } from 'graphql-request';
import type { RequestMiddleware } from 'graphql-request/build/esm/types';
import type { ComputedRef, Ref } from 'vue';
import { isRef } from 'vue';
import useShopStore from '@/stores/shop';
import { isTokenExpired } from '@/utils/isTokenExpired';
import { fetchShopLogin } from './auth/queries/shopLogin';

let awaitRefreshToken: Promise<any> | null = null;
const isRefreshToken = true; // turn-on auto refresh new token

const requestMiddleware: RequestMiddleware = async (request) => {
  const shopStore = useShopStore();
  let token = shopStore.getToken;
  const shopId = shopStore.getShopId;

  // Auto refresh token with
  if (awaitRefreshToken) await awaitRefreshToken;
  if (token && isRefreshToken) {
    if (isTokenExpired(token)) {
      awaitRefreshToken = new Promise((resolve, reject) => {
        try {
          const connectedType = shopStore.getConnectedType;
          fetchShopLogin(connectedType).then((res) => {
            shopStore.setToken(res?.token);
            token = res?.token; // update new token
            resolve(res);
          });
        } catch (e) {
          reject(null);
        }
      });
      await awaitRefreshToken;
      awaitRefreshToken = null;
    }
  }

  // Customize headers
  const headers: Record<string, any> = { ...request.headers };
  const transaction = Sentry?.getCurrentHub()?.getScope()?.getTransaction();
  if (transaction) {
    headers['sentry-trace'] = `${transaction.traceId}-${transaction.spanId}`;
  }

  // Auth with jwt
  if (token && shopId) {
    headers['Authorization'] = `Token ${token}`;
    headers['X-GemX-Shop-ID'] = shopId;
  }

  // Add operation name to url
  const operationName = request?.operationName;
  if (operationName) {
    request.url = `${request.url}?operation=${operationName}`;
  }

  // Return
  return {
    ...request,
    headers,
  };
};

export const client = new GraphQLClient(`${getConfig('url').gateway}graphql/query`, {
  requestMiddleware,
});

export function appFetcher<TData, TVariables>(
  query: string,
  variables?: TVariables | Ref<TVariables> | ComputedRef<TVariables>,
) {
  return async (): Promise<TData> => client.request<TData, any>(query, isRef(variables) ? variables.value : variables);
}
